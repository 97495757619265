<template>
  <div  v-if="pageType==='design'">
        <div class="topOperate">
            <el-page-header @back="goBack" :content="content">返回</el-page-header>
        </div>
       <iframe height="100%" :src="src" frameborder="0"></iframe>
  </div>
  <div  v-else-if="pageType==='preview'">
        <div class="topOperate">
            <el-page-header @back="goBack" :content="content">返回</el-page-header>
        </div>
       <iframe height="100%" :src="src" frameborder="0"></iframe>
  </div>
  <div v-else id="addEdit">
    <div class="topOperate">
        <el-page-header @back="goBack" :content="content">返回</el-page-header>
    </div>
    <el-form :model="formData">
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="表单名称" >
                    <el-input v-model="formData.name" style="width: 100%"  autocomplete="off"></el-input>
                  </el-form-item>
                   <el-form-item label="表单编码" style="width: 100%">
                     <el-input disabled placeholder="系统自动生成" v-if="pageType==='create'" autocomplete="off"></el-input>
                     <el-input disabled v-model="formData.code" v-else autocomplete="off"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="租户" style="width: 100%">
                    <el-select
                                size="small"
                                style="width:100%"
                                v-model="formData.tenantCode"
                                placeholder="请选择"
                                ><el-option
                                    v-for="item in tanantsList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option
                      ></el-select>
                  </el-form-item>
                  <el-form-item label="应用" style="width: 100%">
                    <el-select
                                size="small"
                                style="width:100%"
                                v-model="formData.appCode"
                                placeholder="请选择"
                                ><el-option
                                    v-for="item in aplcationList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option
                      ></el-select>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="状态" style="width: 100%">
                     <el-select
                                size="small"
                                style="width:100%"
                                v-model="formData.status"
                                placeholder="请选择"
                                ><el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option
                      ></el-select>
                  </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item class="last" label="备注" style="width: 100%">
                    <el-input type="textarea" v-model="formData.note" maxlength="255"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
      </el-form>
      <div style="padding-left:30px">
        <el-button type="primary" size="mini" plain @click="camcel">取消</el-button>
        <el-button type="primary" size="mini" @click="submit" >保存</el-button>
      </div>
  </div>
</template>

<script>
import { reqAplictionlist, reqFDadd, reqFDedit, reqFDdetail, reqTenantslist } from '@/api/platform/formDesign'
import Cookies from 'js-cookie'
export default {
    name: 'cedFormDesign',
    data () {
        return {
            content: '', // 标题
            formData: {},
            statusList: [
                { value: 1, label: '无效' },
                { value: 0, label: '有效' }
            ],
            aplcationList: [], // 应用列表
            tanantsList: [], // 租户列表
            codeList: [],
            pageType: '',
            src: ''
        }
    },
    created () {
        this.pageType = this.$route.params.type
        this.content = this.$route.query.title
        if (this.$route.params.type === 'edit') {
            this.getDetail(this.$route.query.id)
        }
        if (this.$route.params.type === 'design') {
            this.src = `http://192.168.0.4:4011?token=${Cookies.get('token')}&code=${this.$route.query.code}`
        }
        if (this.$route.params.type === 'preview') {
            // this.src = `http://192.168.0.4:4011/#/formPreview?token=${Cookies.get('token')}&code=${this.$route.query.code}&id=${this.$route.query.id}&viewTag=${this.$route.query.viewTag}`
            this.src = `http://192.168.0.4:4011/#/formPreview?token=${Cookies.get('token')}&code=${this.$route.query.code}&viewTag=${this.$route.query.viewTag}`
        }
        // 应用列表
        reqAplictionlist().then(({ data }) => {
            this.aplcationList = data
        })
        // 租户列表
        reqTenantslist().then(({ data }) => {
            this.tanantsList = data
        })
    },
    methods: {
        goBack () {
            this.$router.go(-1)
        },
        getDetail (id) {
            reqFDdetail({ id }).then(({ data }) => {
                this.formData = data
            })
        },
        submit () {
            if (this.pageType === 'create') {
                reqFDadd(this.formData).then((res) => {
                    console.log(res)
                    if (res.code === 0) {
                        this.$message.success('新增成功')
                        this.goBack()
                    }
                })
            } else {
                reqFDedit(this.formData).then((res) => {
                    console.log(res)
                    if (res.code === 0) {
                        this.$message.success('编辑成功')
                        this.goBack()
                    }
                })
            }
        },
        camcel () {
            this.goBack()
        }
    }
}
</script>

<style lang="scss">
#addEdit{
  .el-col-12{
    display: flex;
  }
  .el-form-item{
    flex: 1;
    display: flex;
  }
  .el-form-item .el-form-item__label{
    width: 102px !important;
  }
  .last .el-form-item__label{
    width: 92px !important;
  }
  .el-form-item__content{
    width: 90%;
  }
  .el-textarea__inner{
    height: 160px !important;
  }
}
</style>
